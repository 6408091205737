<template>
  <div class="user-group-container">
    <!-- 顶部区域 -->
    <div class="top-box">
      <!--分组下拉列表-->
<!--      <div class="top-left-box">
        <span class="group-name">{{ notGroupItem.groupName }}</span>

        <p class="group-number">{{ notGroupItem.groupNumber }}/{{ personTotal }}</p>

        &lt;!&ndash; 切换分组 &ndash;&gt;
        <ul class="toggle-group">
          <li
            v-for="(item, index) in toggleGroup"
            :key="item.id"
            :class="{ 'select-li': currentGroupIndex === index }"
            @click="toggleGroupClick(item, index)"
          >
            <span>{{ item.groupName }}</span>
            <p class="li-group-number">{{ item.groupNumber }}/{{ personTotal }}</p>
          </li>
        </ul>
      </div>-->

      <!--备注-->
      <div class="top-middle-box">
        <p class="not-group"><u>{{ notGroupItem.groupName }}</u></p>
        <p>{{ $t('Note') }}：{{ notGroupItem.node }}</p>
        <!--<p></p>-->
      </div>

      <!--管理组、批量移至-->
      <div class="top-right-box">
        <el-button
          class="btn-box"
          @click="showManageGroup"
        >{{ $t('ManageGroup') }}</el-button>
        <el-tooltip
          v-if="batchMove"
          effect="dark"
          :content="$t('BatchMoveToTip')"
          placement="top"
        >
          <el-button :disabled="batchMove || right<2">{{ $t('BatchMoveTo') }}</el-button>
        </el-tooltip>
        <el-button
          v-else
          class="btn-box"
          :disabled="batchMove || right<2"
          @click="batchMoveTo"
        >{{ $t('BatchMoveTo') }}</el-button>
      </div>
    </div>
    
    <!--下部区域-->
    <div class="down-box">
      <!--左边分组-->
      <div class="left-group">
        <ul class="infinite-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="stop"
            infinite-scroll-immediate="true"
            infinite-scroll-distance="1"
            style="overflow:auto">
          <!--单个组-->
          <li v-for="(g,index) in toggleGroup" :key="g.id"
              class="per-group"
              :class="{'active':active===g.id, 'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}"
              :ref='g.id'
              @click="g.id === 0 ? null : chooseGroup(g)" >
            <el-tooltip :content="g.groupName" placement="top" effect="dark">
              <span class="ellipsis-tooltip">{{ g.groupName }}</span>
            </el-tooltip>
            <span class="group-num">{{$t('PeopleNumber')+': '+g.groupNumber}}</span>
    
          </li>
  
        </ul>
        <div v-infinite-scroll="loadMore" :infinite-scroll-disabled="isLoading">
          <div v-if="isLoading" class="loading-text">Loading...</div>
        </div>
      </div>
  
      <!--右边列表-->
      <div class="right-form">
        <!--  筛选区域-->
        <div class="filter-box">
          <ul class="filter-left">
            <li>
              <label for="companyName">{{ $t('CompanyName') }}：</label>
              <input
                  type="text"
                  id="companyName"
                  v-model="queryInfo.companyName"
              >
            </li>
            <li>
              <label for="name">{{ $t('RealName') }}：</label>
              <input
                  type="text"
                  id="name"
                  v-model="queryInfo.name"
              >
            </li>
            <li>
              <label for="email">Email：</label>
              <input
                  type="text"
                  id="email"
                  v-model="queryInfo.email"
              >
            </li>
          </ul>
          <div class="filter-right">
            <el-button @click="searchClick">{{ $t('Search') }}</el-button>
          </div>
        </div>
        <!--  表格-->
        <div class="table-box">
          <el-table
              :data="groupList"
              ref="multipleTable"
              border
              style="width: 100%"
              @select="selectChange"
              @select-all="selectAllChange"
          >
            <el-table-column
                type="selection"
                width="55"
                label="全选"
            >
            </el-table-column>
            <!-- 公司名称 -->
            <el-table-column :label="$t('CompanyName')">
              <template slot-scope="scope">
                {{ scope.row.companyName ? scope.row.companyName : '-' }}
              </template>
            </el-table-column>
            <!-- 真实姓名 -->
            <el-table-column :label="$t('RealName')">
              <template slot-scope="scope">
                {{ scope.row.realName ? scope.row.realName : '-' }}
              </template>
            </el-table-column>
            <!-- 在任职位 -->
            <!-- <el-table-column
              prop="job"
              label="在任职位"
            >
            </el-table-column> -->
            <!-- Email -->
            <el-table-column label="Email">
              <template slot-scope="scope">
                {{ scope.row.email ? scope.row.email : '-' }}
              </template>
            </el-table-column>
            <!-- 公司地址 -->
            <el-table-column :label="$t('CompanyAddress')">
              <template slot-scope="scope">
                {{ scope.row.address ? scope.row.address : '-' }}
              </template>
            </el-table-column>
            <!-- 分组 -->
            <el-table-column
                prop="groupName"
                :label="$t('Group')"
            >
            </el-table-column>
            <!-- 操作 -->
            <el-table-column :label="$t('Operation')">
              <template slot-scope="scope">
            <span
                class="move-span"
                @click="showMoveToGroup(scope.row)"
            >{{ $t('MoveTo') }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--  分页-->
        <div class="pagination-box">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="queryInfo.page"
              layout="prev, pager, next"
              :page-size="queryInfo.pageSize"
              :total="queryInfo.total"
              style="margin: 10px auto 10px 20px"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    

    <!-- 筛选区域 -->
<!--    <div class="filter-box">
      <ul class="filter-left">
        <li>
          <label for="companyName">{{ $t('CompanyName') }}：</label>
          <input
            type="text"
            id="companyName"
            v-model="queryInfo.companyName"
          >
        </li>
        <li>
          <label for="name">{{ $t('RealName') }}：</label>
          <input
            type="text"
            id="name"
            v-model="queryInfo.name"
          >
        </li>
        <li>
          <label for="email">Email：</label>
          <input
            type="text"
            id="email"
            v-model="queryInfo.email"
          >
        </li>
      </ul>
      <div class="filter-right">
        <el-button @click="searchClick">{{ $t('Search') }}</el-button>
      </div>
    </div>-->

    <!-- 表格区域 -->
<!--    <div class="table-box">
      <el-table
        :data="groupList"
        ref="multipleTable"
        border
        style="width: 100%"
        @select="selectChange"
        @select-all="selectAllChange"
      >
        <el-table-column
          type="selection"
          width="55"
          label="全选"
        >
        </el-table-column>
        &lt;!&ndash; 公司名称 &ndash;&gt;
        <el-table-column :label="$t('CompanyName')">
          <template slot-scope="scope">
            {{ scope.row.companyName ? scope.row.companyName : '-' }}
          </template>
        </el-table-column>
        &lt;!&ndash; 真实姓名 &ndash;&gt;
        <el-table-column :label="$t('RealName')">
          <template slot-scope="scope">
            {{ scope.row.realName ? scope.row.realName : '-' }}
          </template>
        </el-table-column>
        &lt;!&ndash; 在任职位 &ndash;&gt;
        &lt;!&ndash; <el-table-column
          prop="job"
          label="在任职位"
        >
        </el-table-column> &ndash;&gt;
        &lt;!&ndash; Email &ndash;&gt;
        <el-table-column label="Email">
          <template slot-scope="scope">
            {{ scope.row.email ? scope.row.email : '-' }}
          </template>
        </el-table-column>
        &lt;!&ndash; 公司地址 &ndash;&gt;
        <el-table-column :label="$t('CompanyAddress')">
          <template slot-scope="scope">
            {{ scope.row.address ? scope.row.address : '-' }}
          </template>
        </el-table-column>
        &lt;!&ndash; 分组 &ndash;&gt;
        <el-table-column
          prop="groupName"
          :label="$t('Group')"
        >
        </el-table-column>
        &lt;!&ndash; 操作 &ndash;&gt;
        <el-table-column :label="$t('Operation')">
          <template slot-scope="scope">
            <span
              class="move-span"
              @click="showMoveToGroup(scope.row)"
            >{{ $t('MoveTo') }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>-->

    <!-- 分页区域 -->
<!--    <div class="pagination-box">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        layout="prev, pager, next"
        :page-size="queryInfo.pageSize"
        :total="queryInfo.total"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>-->

    <!-- 管理组对话框 -->
    <ManageGroup
      :show.sync="manageGroup.show"
      :data="manageGroup"
      v-if="manageGroup.show"
    ></ManageGroup>

    <!-- 移至对话框 -->
    <MoveToGroup
      :show.sync="moveToGroup.show"
      :data="moveToGroup"
      v-if="moveToGroup.show"
    ></MoveToGroup>
  </div>
</template>

<script>
import { deepCopy } from "@/utils/tool.js";
import ManageGroup from "./dialog/ManageGroup.vue";
import MoveToGroup from "./dialog/MoveToGroup.vue";
import api from "@/fetch/api";

export default {
  name: "UserGroup",
  components: {
    ManageGroup,
    MoveToGroup,
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      personTotal: 0, // 总人数
      notGroupItem: {}, // 未分组
      currentGroupIndex: 0, // 当前选中组索引
      right:data.userPermission,
      isLoading:false,
      active:0,
      stop:false,

      // 筛选参数
      queryInfo: {
        companyName: "",
        name: "",
        email: "",
        page: 1,
        pageSize: 15,
        total: 0,
      },
      groupList: [], // 用户分组
      selectedList: [], // 点击选中数据

      // 切换分组数据
      toggleGroup: [],

      // 管理组对话框
      manageGroup: {
        show: false,
        data: {},
      },

      // 移至对话框
      moveToGroup: {
        show: false,
        data: {},
        moveType: "item", // 移至类型，item:单个、list:批量
        list: [],
      },
    };
  },
  computed: {
    // 批量移至
    batchMove() {
      if (this.selectedList.length > 1) return false;
      else return true;
    },
  },
  created() {
    this.getGroupMemberList();
  },
  methods: {
    // 获取分组成员列表
    getGroupMemberList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        pageNum: 1,
        pageSize: 999,
        language: languageObj[locale],
        chamberId: data.chamberId,
      };
      api.getGroupByChamberId(params).then((res) => {
        const data = res.data.data.records;
        let total = 0;
        if (data.length) {
          data.forEach((item, index) => {
            if (item.unable === 1 && item.groupNumber !== 0) {
              this.notGroupItem = item;
            } else if (item.unable === 1 && item.groupNumber === 0) {
              // 未分组人员全部分配出去，隐藏未分组，显示下一个组别
              this.notGroupItem = data[index + 1];
            }
            // 处理分组人数
            total += item.groupNumber;
          });
        }
        this.personTotal = total; // 总人数
        this.toggleGroup = data;
        this.active = this.toggleGroup[0].id
        this.groupId = this.toggleGroup[0].groupId
        console.log('this.toggleGroup ->',this.toggleGroup)

        this.getUserGroupList(1,this.groupId);
      });
    },

    // 获取用户分组列表
    getUserGroupList(page,groupId) {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const params = {
        pageNum: page,
        pageSize: this.queryInfo.pageSize,
        language: languageObj[locale],
        groupId: groupId,
      };

      if (this.queryInfo.companyName.trim())
        params.companyName = this.queryInfo.companyName;
      if (this.queryInfo.name.trim()) params.realName = this.queryInfo.name;
      if (this.queryInfo.email.trim()) params.email = this.queryInfo.email;

      api.queryGroupList(params).then((res) => {
        this.groupList = res.data.data.records;
        this.queryInfo.total = res.data.data.total;
      });
    },

    // 切换组
/*    toggleGroupClick(row, index) {
      this.currentGroupIndex = index;
      this.notGroupItem = {};
      this.notGroupItem = row;
      this.queryInfo.page = 1;
      this.getUserGroupList();
    },*/
  
    // 切组
    chooseGroup(group){
      // console.log('group.id ->',group.id)
      this.notGroupItem = {};
      this.notGroupItem = group;
      this.active = group.id
      this.groupId = group.groupId
      this.getUserGroupList(1,group.groupId)
    },

    // 搜索
    searchClick() {
      this.queryInfo.page = 1;
      this.getUserGroupList();
    },

    // 分页
    handleCurrentChange(page) {
      this.queryInfo.page = page;
      this.getUserGroupList();
    },

    // 显示管理组对话框
    showManageGroup() {
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.manageGroup.show = true;
      }
    },

    // 每行 checkbox 点击
    selectChange(selection, row) {
      if (row.isMove === 2) {
        this.$message.error({ message: this.$t("NotPayTip") });
        this.$refs.multipleTable.toggleRowSelection(row, false);
      } else {
        if (this.selectedList.length > 0) {
          const foundIndex = this.selectedList.findIndex(
            (item) => item.id === row.id
          );
          if (foundIndex !== -1) {
            this.selectedList.splice(foundIndex, 1);
            this.$refs.multipleTable.toggleRowSelection(row, false);
          } else {
            this.selectedList.push(row);
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        } else {
          this.selectedList.push(row);
          this.$refs.multipleTable.toggleRowSelection(row, true);
        }
      }
    },

    // 全选
    selectAllChange(selection) {
      this.$refs.multipleTable.clearSelection();
      const tableData = this.groupList.filter((item) => item.isMove !== 2);
      const selectedList = deepCopy(this.selectedList);
      this.selectedList = [];

      if (selectedList.length !== tableData.length) {
        selection.forEach((item) => {
          if (item.isMove !== 2) {
            this.selectedList.push(item);
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        });
      }
    },

    // 批量移至
    batchMoveTo() {
      this.moveToGroup.show = true;
      this.moveToGroup.data = {};
      this.moveToGroup.moveType = "list";
      this.moveToGroup.list = this.selectedList;
    },

    // 显示移至对话框
    showMoveToGroup(row) {
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        if (row.isMove === 2) {
          this.$message.error({ message: this.$t("NotPayTip") });
        } else {
          this.moveToGroup.show = true;
          this.moveToGroup.data = row;
          this.moveToGroup.moveType = "item";
          this.moveToGroup.list = [];
        }
      }
    },
  
    // 无限滚动组
    loadMore(){
      // 如果不是最后一页，加载
      if(this.page<this.maxPage){
        this.load = true
        this.page += 1
        this.getGroups(this.page)
      }
      else if(this.page===this.maxPage){
        this.stop = true
        //   如果是最后一页
        /*let obj = {
          id:0,
          groupName: '到底了',
          end:true
        }
        this.groups.push(obj)
        this.$nextTick(()=>{
          const endElement = this.$refs[0][0]
          if(endElement){
            endElement.style.height = '50px'; // 设置高度
            endElement.style.backgroundColor = '#7F8387'
            endElement.style.color = 'white'
          }
        })*/
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-group-container {
  width: 1600px;
  display: flex;
  flex-flow: column nowrap;
  ul,
  li {
    list-style: none;
  }
  // 顶部区域
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #677D95;
    color: white;
    padding: 10px;
    border-radius: 5px;
    
    & > .top-left-box {
      position: relative;
      width: 200px;
      height: 70px;
      line-height: 68px;
      text-align: center;
      background-color: #425d7b;
      color: #ffffff;
      border-radius: 5px;

      & > .group-name {
        display: inline-block;
        width: 100%;
        padding: 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > .group-number {
        position: absolute;
        top: 53px;
        left: 150px;
        line-height: 16px;
        font-size: 12px;
      }

      // 切换分组
      .toggle-group {
        display: none;
        position: absolute;
        top: 69px;
        z-index: 999;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        // 滚动条宽度为0（兼容火狐）
        scrollbar-width: none;
        // 滚动条宽度为0
        &::-webkit-scrollbar {
          display: none;
        }
        border: 1px solid #dbdbdb;
        & > li {
          position: relative;
          padding: 0 10px;
          line-height: 60px;
          background-color: #eaf3fd;
          color: black;
          border-bottom: 1px solid #dbdbdb;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius: 5px;

          & > .li-group-number {
            position: absolute;
            top: 45px;
            left: 150px;
            line-height: 16px;
            font-size: 12px;
          }
        }
        & > li:last-child {
          border-bottom: none;
        }
        // 选中样式
        .select-li {
          background-color: #425d7b;
          color: #ffffff;
        }
      }
      &:hover .toggle-group {
        display: block;
      }
    }

    & > .top-middle-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      .not-group {
        margin-bottom: 15px;
      }
    }

    & > .top-right-box {
      display: flex;
      flex-flow: row nowrap;
      .el-button {
        min-width: 150px;
        height: 40px;
        background-color: inherit;
        //background-color: #425d7b;
      }
      .btn-box {
        color: #ffffff;
      }
      .el-button:last-child {
        margin-left: 30px;
      }
      .el-button:first-child {
        //margin-bottom: 10px;
      }
    }
  }

  // 筛选区域
  .filter-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #677d95;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 5px;
    & > .filter-left {
      display: flex;
      li {
        margin-right: 30px;
      }
      li:last-child {
        margin-right: 0;
      }
      label {
        color: white;
      }
      input {
        width: 200px;
        height: 30px;
        margin: 8px 0;
        border: 1px solid white;
        vertical-align: middle;
      }
    }

    & > .filter-right {
      .el-button {
        min-width: 200px;
        background: transparent;
        color: #ffffff;
        border: 1px solid #d4dbe5;
      }
    }
  }

  // 表格区域
  .table-box {
    margin-top: 10px;
    .move-span {
      color: #82b6fa;
      cursor: pointer;
    }
  }
}

.down-box{
  display: flex;
  flex-flow: row nowrap;
  
  .left-group{
    width: 16%;
    max-height: 830px;
    overflow-y: auto;
    //overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    margin-right: 15px;
    margin-top: 10px;
    
    .per-group{
      height: 100px;
      border: 0.5px solid #EBEEF5;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 15px;
      line-height: 30px;
      justify-content: center;
      cursor: pointer;
      position: relative;
    }
    li:not(:last-of-type) {
      border-bottom:none
    }
    /* 偶数行样式 */
    .per-group.even-row {
      background-color: #f2f2f2; /* 偶数行的背景颜色 */
    }
  
    /* 奇数行样式 */
    .per-group.odd-row {
      background-color: #ffffff; /* 奇数行的背景颜色 */
    }
  
    /* 激活（选中）行样式 */
    .per-group.active {
      background-color: #677D95;
      color: white;
    }
  
    .per-group:hover{
      background-color: #F5F7FA;
      color: black;
    }
    .infinite-list {
      max-height: 830px;
      overflow-y: auto;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    .ellipsis-tooltip {
      white-space: nowrap;      /* 防止文本换行 */
      overflow: hidden;         /* 隐藏溢出的内容 */
      text-overflow: ellipsis;  /* 使用省略号代替溢出内容 */
      max-width: 100%;          /* 限制最大宽度 */
    }
    .per-group:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .per-group:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  
  .active {
    //background-color: #1C252C;
    background-color: #677d95 !important;
    color: white !important;
  }
  .group-num{
    font-size: 12px;
    position: absolute;
    bottom: 10px;
    right:20px
  }
  
  .right-form{
    width: 84%;
  }
}

</style>