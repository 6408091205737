<template>

  <el-dialog
    :title="$t('MoveTo')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="900px"
    class="move-to-group"
  >
    <!-- 表格区域 -->
    <div class="table-box">
      <table>
        <!-- 未分组成员 -->
        <tr :class="{ 'tr-select': notGroupItem.groupId === selectGroupId }">
          <td class="radio-td">
            <el-radio
              v-model="selectGroupId"
              :label="notGroupItem.groupId"
            >{{''}}</el-radio>
          </td>
          <td class="group-td">
            <span>{{ notGroupItem.groupName }}</span>
          </td>
          <td class="group-td">
            <span>{{ notGroupItem.node }}</span>
          </td>
          <td class="number-td">
            <div class="td-flex">
              <span>{{ $t('PeopleNumber') }}</span>
              <span>{{ notGroupItem.groupNumber }}</span>
            </div>
          </td>
        </tr>
        <tr
          v-for="item in groupList"
          :key="item.id"
          :class="{ 'tr-select': item.groupId === selectGroupId }"
        >
          <td class="radio-td">
            <el-radio
              v-model="selectGroupId"
              :label="item.groupId"
            >{{''}}</el-radio>
          </td>
          <td class="group-td">
            <span v-if="item.isRemarkShow">{{ item.groupName }}</span>
            <el-input
              v-else
              v-model="item.groupName"
              :placeholder="$t('GroupNamePlaceholder')"
              maxlength="50"
            ></el-input>
          </td>
          <td class="group-td">
            <span v-if="item.isRemarkShow">{{ item.node }}</span>
            <el-input
              v-else
              v-model="item.node"
              :placeholder="$t('RemarkPlaceholder')"
              maxlength="100"
            ></el-input>
          </td>
          <td class="number-td">
            <div class="td-flex">
              <span>{{ $t('PeopleNumber') }}</span>
              <span>{{ item.groupNumber }}</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <el-button
      v-if="showAddGroupBtn"
      class="add-group"
      @click="addGroup"
    >{{ $t('AddGroup') }}</el-button>

    <span slot="footer">
      <el-button @click="cancelFn">{{ $t('Cancle') }}</el-button>
      <el-button
        type="primary"
        @click="submitData"
      >{{ $t('Confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deepCopy } from "@/utils/tool.js";
import api from "@/fetch/api";

export default {
  name: "MoveToGroup",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set() {
        this.cancelFn();
      },
    },
  },
  data() {
    return {
      moveType: "item", // 移至类型，item:单个、list:批量
      notGroupItem: {}, // 未分组
      groupList: [], // 分组数据
      selectGroupId: "", // 分组选中id

      // 新建组
      showAddGroupBtn: true, // 是否显示新建组按钮
      addGroupId: "", // 自定义新增对象id
      operateType: "moveTo", // 操作类型
    };
  },
  created() {
    this.moveType = this.data.moveType;
    this.getGroupMemberList();
  },
  methods: {
    // 获取分组成员列表
    getGroupMemberList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        pageNum: 1,
        pageSize: 999,
        language: languageObj[locale],
        chamberId: data.chamberId,
      };
      api.getGroupByChamberId(params).then((res) => {
        const copyData = deepCopy(res.data.data.records);
        const filterArr = [];
        if (copyData.length) {
          copyData.forEach((item) => {
            item.isNameShow = true;
            item.isRemarkShow = true;

            // 未分组成员
            if (item.unable === 1) {
              this.notGroupItem = item;
            } else {
              filterArr.push(item);
            }
          });
        }
        this.groupList = filterArr;
      });
    },

    // 取消
    cancelFn() {
      this.$emit("update:show", false);
    },

    // 新建组
    addGroup() {
      this.showAddGroupBtn = false;
      this.operateType = "addGroupMoveTo";
      this.selectGroupId = "888888";
      const itemObj = {
        id: 999999,
        groupId: "888888",
        groupName: "",
        node: "",
        groupNumber: 0,
        isNameShow: false,
        isRemarkShow: false,
      };
      this.addGroupId = itemObj.id;
      this.groupList.push(itemObj);
    },

    // 确定
    submitData() {
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;

      // 单个移至
      if (this.moveType === "item") {
        // 1、移至（未新建组）
        if (this.operateType === "moveTo") {
          if (!this.selectGroupId)
            return this.$message.error({
              message: this.$t("SelectGroupTip"),
              duration: 2000,
            });

          const list = [
            {
              createUserId: data.id,
              updateUserId: data.id,
              userType: 1, // 1:商会用户、2:律所用户
              systemId: data.chamberId, // 商会id
              userId: this.data.data.userId, // 用户id
              groupId: this.selectGroupId, // 分组id
            },
          ];

          api.moveToGroup(list).then(() => {
            this.$parent.getGroupMemberList();
            this.$message.success({
              message: this.$t("OperationSuccessTip"),
              duration: 1500,
            });
            this.cancelFn();
          });
        } else if (this.operateType === "addGroupMoveTo") {
          // 2、移至（进行新建组动作）
          let addItem = {};
          if (this.groupList.length) {
            this.groupList.forEach((item) => {
              if (this.addGroupId === item.id) addItem = item;
            });
          }

          if (!addItem.groupName.trim()) {
            return this.$message.error({
              message: this.$t("GroupNameEmpty"),
              duration: 2000,
            });
          }

          if (!addItem.node.trim()) {
            return this.$message.error({
              message: this.$t("RemarkEmpty"),
              duration: 2000,
            });
          }

          const params = {
            groupName: addItem.groupName,
            node: addItem.node,
            chamberId: data.chamberId,
            createUserId: data.id,
          };

          api.createGroup(params).then((res) => {
            /* 
            1、新建组，按默认选中移至
            2、新建组并且选择其他分组移至
          */
            const groupId =
              this.selectGroupId === "888888"
                ? res.data.msg
                : this.selectGroupId;

            const list = [
              {
                createUserId: data.id,
                updateUserId: data.id,
                userType: 1, // 1:商会用户、2:律所用户
                systemId: data.chamberId, // 商会id
                userId: this.data.data.userId, // 用户id
                groupId, // 分组id
              },
            ];

            api.moveToGroup(list).then(() => {
              this.$parent.getGroupMemberList();
              this.$message.success({
                message: this.$t("OperationSuccessTip"),
                duration: 1500,
              });
              this.cancelFn();
            });
          });
        }
      } else {
        // 批量移至
        // 1、批量移至（未新建组）
        if (this.operateType === "moveTo") {
          if (!this.selectGroupId)
            return this.$message.error({
              message: this.$t("SelectGroupTip"),
              duration: 2000,
            });

          const selectData = this.data.list;
          const filterArr = [];
          selectData.forEach((item) => {
            const params = {
              createUserId: data.id,
              updateUserId: data.id,
              userType: 1, // 1:商会用户、2:律所用户
              systemId: data.chamberId, // 商会id
              userId: item.userId, // 用户id
              groupId: this.selectGroupId, // 分组id
            };
            filterArr.push(params);
          });

          api.moveToGroup(filterArr).then(() => {
            this.$parent.getGroupMemberList();
            this.$message.success({
              message: this.$t("OperationSuccessTip"),
              duration: 1500,
            });
            this.cancelFn();
          });
        } else if (this.operateType === "addGroupMoveTo") {
          // 2、移至（进行新建组动作）
          let addItem = {};
          if (this.groupList.length) {
            this.groupList.forEach((item) => {
              if (this.addGroupId === item.id) addItem = item;
            });
          }

          if (!addItem.groupName.trim()) {
            return this.$message.error({
              message: this.$t("GroupNameEmpty"),
              duration: 2000,
            });
          }

          if (!addItem.node.trim()) {
            return this.$message.error({
              message: this.$t("RemarkEmpty"),
              duration: 2000,
            });
          }

          const params = {
            groupName: addItem.groupName,
            node: addItem.node,
            chamberId: data.chamberId,
            createUserId: data.id,
          };

          api.createGroup(params).then((res) => {
            /* 
            1、新建组，按默认选中移至
            2、新建组并且选择其他分组移至
          */
            const groupId =
              this.selectGroupId === "888888"
                ? res.data.msg
                : this.selectGroupId;

            const selectData = this.data.list;
            const filterArr = [];
            selectData.forEach((item) => {
              const params = {
                createUserId: data.id,
                updateUserId: data.id,
                userType: 1, // 1:商会用户、2:律所用户
                systemId: data.chamberId, // 商会id
                userId: item.userId, // 用户id
                groupId, // 分组id
              };
              filterArr.push(params);
            });

            api.moveToGroup(filterArr).then(() => {
              this.$parent.getGroupMemberList();
              this.$message.success({
                message: this.$t("OperationSuccessTip"),
                duration: 1500,
              });
              this.cancelFn();
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.move-to-group {
  // 表格区域
  .table-box {
    max-height: 450px;
    overflow-y: auto;
    // 滚动条宽度为0（兼容火狐）
    scrollbar-width: none;
    // 滚动条宽度为0
    &::-webkit-scrollbar {
      display: none;
    }
    table {
      width: 100%;
    }
    table,
    td {
      border: 1px solid #ebebeb;
      font-size: 12px;
      border-collapse: collapse;
    }
    tr {
      height: 35px;
    }
    // 选中
    .tr-select {
      background-color: #deecfe;
    }
    td {
      padding: 0 10px;
      ::v-deep .el-input__inner {
        background-color: transparent;
        padding: 0;
        border: none;
      }
    }
    .radio-td {
      width: 50px;
      text-align: center;
    }
    .group-td {
      max-width: 250px;
      & > span {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .number-td {
      width: 170px;
    }
    .td-flex {
      display: flex;
      justify-content: space-between;
    }
  }

  .add-group {
    background-color: #589ef8;
    margin-top: 10px;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
.move-to-group {
  .el-dialog__header,
  .el-dialog__footer {
    text-align: center;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    .el-button {
      margin: 0 10px;
    }
  }
}
</style>